import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import DataDashboardSidepanel from '@/components/datadashboardcomponent/datadashboardsidepanel/DataDashboardSidepanel';

@Component({
  components: {
    'datadashboard-sidepanel': DataDashboardSidepanel
  }
})
export default class leftSidepanel extends Vue {
    private objScreenText: ScreenText = new ScreenText();

    @Prop()
    siteList!:any;

    @Prop()
    roleId!: number;

    @Prop()
    userDetails!: any;

    @Prop()
    activeLeftPanelTab!: string;

    @Prop()
    selectedSiteId!:number;

    @Prop()
    hidequalityScore!: boolean;

    @Watch('clearPreviousData',{deep: true})
      
    public getScreenText(key: string): string {
      return this.objScreenText.getScreenText(key);
    }
    
    showGraphInfo(tab:string){
      this.$emit('showGraphInfo', tab);
      this.closepanel();
    }

    closepanel(){
      this.$emit('closePanel', true);
    }
    editModeFromGraph(qualityData:any){
      const openEditPopup:any=this.$refs.sidepanelDesktop1;
      openEditPopup.editModeFromGraph(qualityData,1);
  
    }

    updateMobileGraphQuality(scoreDataUpdated:any, week:number, averageScore:number){
      this.$emit('updateGraphQuality', scoreDataUpdated);
    }
}